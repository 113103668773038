import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import { Link } from 'gatsby';
import { SEO } from '../components';

const FourOFourPage: FunctionComponent = (): ReactElement => (
    <>
        <SEO title="404"/>
        <section>
            <h1>Sorry nothing to see here!</h1>
            <Link to="/">Go Home</Link>
        </section>
    </>
);

export default FourOFourPage;
